
@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}


 
::-webkit-scrollbar{
  background: rgb(255, 255, 255);
  width: 10px;
  z-index: 2000;
}

::-webkit-scrollbar-thumb{
  background: #000000;
  position: relative;
  z-index: 999;
 border-radius: 5px;
 border: 1px solid #fff; 
}


@media  screen and (max-width: 768px) {
  ::-webkit-scrollbar{
    width: 0;
  }
}

body {

  font-family: Poppins ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

 .heading{
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  position: relative;
  z-index: 1000;
  color: transparent;
  background: linear-gradient(-45deg,rgb(26, 152, 255),rgb(0, 94, 255), rgb(28, 43, 255), rgb(106, 0, 255), rgb(255, 0, 251));
  background-clip: text;
} 

.heading::before{
  content: "";
  position: absolute;
  width: 10px; height: 10px;
  background-color: rgb(26, 152, 255);
  bottom: -3px; left: 18px;
  border-radius: 50%;
}
.heading::after{
  content: "";
  position: absolute;
  width: 115%; height: 4px;
  background: linear-gradient(90deg,rgb(26, 152, 255),rgb(0, 94, 255), rgb(28, 43, 255), rgb(106, 0, 255), rgb(255, 0, 251));
  bottom: 0px; left: 20px;
  border-radius: 10px;
}


/* styles.css */

.cursor-trail {
  pointer-events: none; /* Prevent the canvas from blocking mouse events */
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 9999999; /* Ensure it is on top of other elements */
}





